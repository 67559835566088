.currency-flag {
    display: inline-block;
    width: 24px;
    height: 16px;
    background-size: cover;
    background-image:url('../images/flags/rectangle_fallback.png');
  }
  
  .currency-flag-sm {
    width: 16px;
    height: 10px;
  }
  
  .currency-flag-lg {
    width: 36px;
    height: 24px;
  }
  
  .currency-flag-xl {
    width: 48px;
    height: 32px;
  }
  .currency-flag.currency-flag-aed{background-image:url('../images/flags/aed.png');}
  .currency-flag.currency-flag-afn{background-image:url('../images/flags/afn.png');}
  .currency-flag.currency-flag-all{background-image:url('../images/flags/all.png');}
  .currency-flag.currency-flag-amd{background-image:url('../images/flags/amd.png');}
  .currency-flag.currency-flag-ang{background-image:url('../images/flags/ang.png');}
  .currency-flag.currency-flag-aoa{background-image:url('../images/flags/aoa.png');}
  .currency-flag.currency-flag-ars{background-image:url('../images/flags/ars.png');}
  .currency-flag.currency-flag-aud{background-image:url('../images/flags/aud.png');}
  .currency-flag.currency-flag-awg{background-image:url('../images/flags/awg.png');}
  .currency-flag.currency-flag-azn{background-image:url('../images/flags/azn.png');}
  .currency-flag.currency-flag-bam{background-image:url('../images/flags/bam.png');}
  .currency-flag.currency-flag-bbd{background-image:url('../images/flags/bbd.png');}
  .currency-flag.currency-flag-bdt{background-image:url('../images/flags/bdt.png');}
  .currency-flag.currency-flag-bgn{background-image:url('../images/flags/bgn.png');}
  .currency-flag.currency-flag-bhd{background-image:url('../images/flags/bhd.png');}
  .currency-flag.currency-flag-bif{background-image:url('../images/flags/bif.png');}
  .currency-flag.currency-flag-bmd{background-image:url('../images/flags/bmd.png');}
  .currency-flag.currency-flag-bnd{background-image:url('../images/flags/bnd.png');}
  .currency-flag.currency-flag-bob{background-image:url('../images/flags/bob.png');}
  .currency-flag.currency-flag-brl{background-image:url('../images/flags/brl.png');}
  .currency-flag.currency-flag-bsd{background-image:url('../images/flags/bsd.png');}
  .currency-flag.currency-flag-btn{background-image:url('../images/flags/btn.png');}
  .currency-flag.currency-flag-bwp{background-image:url('../images/flags/bwp.png');}
  .currency-flag.currency-flag-byn{background-image:url('../images/flags/byn.png');}
  .currency-flag.currency-flag-bzd{background-image:url('../images/flags/bzd.png');}
  .currency-flag.currency-flag-cad{background-image:url('../images/flags/cad.png');}
  .currency-flag.currency-flag-cdf{background-image:url('../images/flags/cdf.png');}
  .currency-flag.currency-flag-chf{background-image:url('../images/flags/chf.png');}
  .currency-flag.currency-flag-clp{background-image:url('../images/flags/clp.png');}
  .currency-flag.currency-flag-cny{background-image:url('../images/flags/cny.png');}
  .currency-flag.currency-flag-cop{background-image:url('../images/flags/cop.png');}
  .currency-flag.currency-flag-crc{background-image:url('../images/flags/crc.png');}
  .currency-flag.currency-flag-cup{background-image:url('../images/flags/cup.png');}
  .currency-flag.currency-flag-cve{background-image:url('../images/flags/cve.png');}
  .currency-flag.currency-flag-czk{background-image:url('../images/flags/czk.png');}
  .currency-flag.currency-flag-djf{background-image:url('../images/flags/djf.png');}
  .currency-flag.currency-flag-dkk{background-image:url('../images/flags/dkk.png');}
  .currency-flag.currency-flag-dop{background-image:url('../images/flags/dop.png');}
  .currency-flag.currency-flag-dzd{background-image:url('../images/flags/dzd.png');}
  .currency-flag.currency-flag-egp{background-image:url('../images/flags/egp.png');}
  .currency-flag.currency-flag-ern{background-image:url('../images/flags/ern.png');}
  .currency-flag.currency-flag-etb{background-image:url('../images/flags/etb.png');}
  .currency-flag.currency-flag-eur{background-image:url('../images/flags/eur.png');}
  .currency-flag.currency-flag-fjd{background-image:url('../images/flags/fjd.png');}
  .currency-flag.currency-flag-fkp{background-image:url('../images/flags/fkp.png');}
  .currency-flag.currency-flag-gbp{background-image:url('../images/flags/gbp.png');}
  .currency-flag.currency-flag-gel{background-image:url('../images/flags/gel.png');}
  .currency-flag.currency-flag-ghs{background-image:url('../images/flags/ghs.png');}
  .currency-flag.currency-flag-gip{background-image:url('../images/flags/gip.png');}
  .currency-flag.currency-flag-gmd{background-image:url('../images/flags/gmd.png');}
  .currency-flag.currency-flag-gnf{background-image:url('../images/flags/gnf.png');}
  .currency-flag.currency-flag-gtq{background-image:url('../images/flags/gtq.png');}
  .currency-flag.currency-flag-gyd{background-image:url('../images/flags/gyd.png');}
  .currency-flag.currency-flag-hkd{background-image:url('../images/flags/hkd.png');}
  .currency-flag.currency-flag-hnl{background-image:url('../images/flags/hnl.png');}
  .currency-flag.currency-flag-hrk{background-image:url('../images/flags/hrk.png');}
  .currency-flag.currency-flag-htg{background-image:url('../images/flags/htg.png');}
  .currency-flag.currency-flag-huf{background-image:url('../images/flags/huf.png');}
  .currency-flag.currency-flag-idr{background-image:url('../images/flags/idr.png');}
  .currency-flag.currency-flag-ils{background-image:url('../images/flags/ils.png');}
  .currency-flag.currency-flag-inr{background-image:url('../images/flags/inr.png');}
  .currency-flag.currency-flag-iqd{background-image:url('../images/flags/iqd.png');}
  .currency-flag.currency-flag-irr{background-image:url('../images/flags/irr.png');}
  .currency-flag.currency-flag-isk{background-image:url('../images/flags/isk.png');}
  .currency-flag.currency-flag-jmd{background-image:url('../images/flags/jmd.png');}
  .currency-flag.currency-flag-jod{background-image:url('../images/flags/jod.png');}
  .currency-flag.currency-flag-jpy{background-image:url('../images/flags/jpy.png');}
  .currency-flag.currency-flag-kes{background-image:url('../images/flags/kes.png');}
  .currency-flag.currency-flag-kgs{background-image:url('../images/flags/kgs.png');}
  .currency-flag.currency-flag-khr{background-image:url('../images/flags/khr.png');}
  .currency-flag.currency-flag-kmf{background-image:url('../images/flags/kmf.png');}
  .currency-flag.currency-flag-kpw{background-image:url('../images/flags/kpw.png');}
  .currency-flag.currency-flag-krw{background-image:url('../images/flags/krw.png');}
  .currency-flag.currency-flag-kwd{background-image:url('../images/flags/kwd.png');}
  .currency-flag.currency-flag-kyd{background-image:url('../images/flags/kyd.png');}
  .currency-flag.currency-flag-kzt{background-image:url('../images/flags/kzt.png');}
  .currency-flag.currency-flag-lak{background-image:url('../images/flags/lak.png');}
  .currency-flag.currency-flag-lbp{background-image:url('../images/flags/lbp.png');}
  .currency-flag.currency-flag-lkr{background-image:url('../images/flags/lkr.png');}
  .currency-flag.currency-flag-lrd{background-image:url('../images/flags/lrd.png');}
  .currency-flag.currency-flag-ltl{background-image:url('../images/flags/ltl.png');}
  .currency-flag.currency-flag-lyd{background-image:url('../images/flags/lyd.png');}
  .currency-flag.currency-flag-mad{background-image:url('../images/flags/mad.png');}
  .currency-flag.currency-flag-mdl{background-image:url('../images/flags/mdl.png');}
  .currency-flag.currency-flag-mga{background-image:url('../images/flags/mga.png');}
  .currency-flag.currency-flag-mkd{background-image:url('../images/flags/mkd.png');}
  .currency-flag.currency-flag-mmk{background-image:url('../images/flags/mmk.png');}
  .currency-flag.currency-flag-mnt{background-image:url('../images/flags/mnt.png');}
  .currency-flag.currency-flag-mop{background-image:url('../images/flags/mop.png');}
  .currency-flag.currency-flag-mro{background-image:url('../images/flags/mro.png');}
  .currency-flag.currency-flag-mur{background-image:url('../images/flags/mur.png');}
  .currency-flag.currency-flag-mvr{background-image:url('../images/flags/mvr.png');}
  .currency-flag.currency-flag-mwk{background-image:url('../images/flags/mwk.png');}
  .currency-flag.currency-flag-mxn{background-image:url('../images/flags/mxn.png');}
  .currency-flag.currency-flag-myr{background-image:url('../images/flags/myr.png');}
  .currency-flag.currency-flag-mzn{background-image:url('../images/flags/mzn.png');}
  .currency-flag.currency-flag-nad{background-image:url('../images/flags/nad.png');}
  .currency-flag.currency-flag-ngn{background-image:url('../images/flags/ngn.png');}
  .currency-flag.currency-flag-nio{background-image:url('../images/flags/nio.png');}
  .currency-flag.currency-flag-nok{background-image:url('../images/flags/nok.png');}
  .currency-flag.currency-flag-npr{background-image:url('../images/flags/npr.png');}
  .currency-flag.currency-flag-nzd{background-image:url('../images/flags/nzd.png');}
  .currency-flag.currency-flag-omr{background-image:url('../images/flags/omr.png');}
  .currency-flag.currency-flag-pen{background-image:url('../images/flags/pen.png');}
  .currency-flag.currency-flag-pgk{background-image:url('../images/flags/pgk.png');}
  .currency-flag.currency-flag-php{background-image:url('../images/flags/php.png');}
  .currency-flag.currency-flag-pkr{background-image:url('../images/flags/pkr.png');}
  .currency-flag.currency-flag-pln{background-image:url('../images/flags/pln.png');}
  .currency-flag.currency-flag-pyg{background-image:url('../images/flags/pyg.png');}
  .currency-flag.currency-flag-qar{background-image:url('../images/flags/qar.png');}
  .currency-flag.currency-flag-ron{background-image:url('../images/flags/ron.png');}
  .currency-flag.currency-flag-rsd{background-image:url('../images/flags/rsd.png');}
  .currency-flag.currency-flag-rub{background-image:url('../images/flags/rub.png');}
  .currency-flag.currency-flag-rwf{background-image:url('../images/flags/rwf.png');}
  .currency-flag.currency-flag-sar{background-image:url('../images/flags/sar.png');}
  .currency-flag.currency-flag-sbd{background-image:url('../images/flags/sbd.png');}
  .currency-flag.currency-flag-scr{background-image:url('../images/flags/scr.png');}
  .currency-flag.currency-flag-sek{background-image:url('../images/flags/sek.png');}
  .currency-flag.currency-flag-sgd{background-image:url('../images/flags/sgd.png');}
  .currency-flag.currency-flag-shp{background-image:url('../images/flags/shp.png');}
  .currency-flag.currency-flag-sll{background-image:url('../images/flags/sll.png');}
  .currency-flag.currency-flag-sos{background-image:url('../images/flags/sos.png');}
  .currency-flag.currency-flag-srd{background-image:url('../images/flags/srd.png');}
  .currency-flag.currency-flag-std{background-image:url('../images/flags/std.png');}
  .currency-flag.currency-flag-svc{background-image:url('../images/flags/svc.png');}
  .currency-flag.currency-flag-syp{background-image:url('../images/flags/syp.png');}
  .currency-flag.currency-flag-szl{background-image:url('../images/flags/szl.png');}
  .currency-flag.currency-flag-thb{background-image:url('../images/flags/thb.png');}
  .currency-flag.currency-flag-tjs{background-image:url('../images/flags/tjs.png');}
  .currency-flag.currency-flag-tnd{background-image:url('../images/flags/tnd.png');}
  .currency-flag.currency-flag-top{background-image:url('../images/flags/top.png');}
  .currency-flag.currency-flag-try{background-image:url('../images/flags/try.png');}
  .currency-flag.currency-flag-ttd{background-image:url('../images/flags/ttd.png');}
  .currency-flag.currency-flag-twd{background-image:url('../images/flags/twd.png');}
  .currency-flag.currency-flag-tzs{background-image:url('../images/flags/tzs.png');}
  .currency-flag.currency-flag-uah{background-image:url('../images/flags/uah.png');}
  .currency-flag.currency-flag-ugx{background-image:url('../images/flags/ugx.png');}
  .currency-flag.currency-flag-usd{background-image:url('../images/flags/usd.png');}
  .currency-flag.currency-flag-uyu{background-image:url('../images/flags/uyu.png');}
  .currency-flag.currency-flag-uzs{background-image:url('../images/flags/uzs.png');}
  .currency-flag.currency-flag-vef{background-image:url('../images/flags/vef.png');}
  .currency-flag.currency-flag-vnd{background-image:url('../images/flags/vnd.png');}
  .currency-flag.currency-flag-vuv{background-image:url('../images/flags/vuv.png');}
  .currency-flag.currency-flag-wst{background-image:url('../images/flags/wst.png');}
  .currency-flag.currency-flag-xaf{background-image:url('../images/flags/xaf.png');}
  .currency-flag.currency-flag-xcd{background-image:url('../images/flags/xcd.png');}
  .currency-flag.currency-flag-xof{background-image:url('../images/flags/xof.png');}
  .currency-flag.currency-flag-xpf{background-image:url('../images/flags/xpf.png');}
  .currency-flag.currency-flag-yer{background-image:url('../images/flags/yer.png');}
  .currency-flag.currency-flag-zar{background-image:url('../images/flags/zar.png');}
  .currency-flag.currency-flag-zmw{background-image:url('../images/flags/zmw.png');}
  
  
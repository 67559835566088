//
// Bootstrap-custom.scss
//

@each $heading, $size in $heading-font-sizes {
  #{$heading} {
    font-size: $size !important;
  }
}

@each $heading, $size in $display-font-sizes {
  .#{$heading} {
    font-size: $size !important;
  }
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }
  .bg-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }
  a {
    transition: all 0.5s ease;
    &.text-#{$name} {
      &:hover,
      &:focus {
        color: darken($value, 5%) !important;
      }
    }
  }
}

.bg-black {
  background: $black !important;
}

//li (list inline item)
.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

// Rounded
.rounded {
  border-radius: 16px !important;
}
.rounded-label {
  border-radius: 16px !important;
}

.rounded-btn {
  border-radius: 64px !important;
}
.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right-with-borders {
  border-top-right-radius: 64px !important;
  border-bottom-right-radius: 64px !important;
}

.rounded-left-with-borders {
  border-top-left-radius: 64px !important;
  border-bottom-left-radius: 64px !important;
  
}

.rounded-top-left-bottom-right {
  border-top-left-radius: 200px !important;
  border-bottom-right-radius: 200px !important;

  @media (min-width: 768px) and (max-width: 1023px) {
    border-top-left-radius: 140px !important;
    border-bottom-right-radius: 140px !important;
  }
}

.rounded-top-right-bottom-left {
  border-top-right-radius: 200px !important;
  border-bottom-left-radius: 200px !important;

  @media (min-width: 768px) and (max-width: 1023px) {
    border-top-right-radius: 140px !important;
    border-bottom-left-radius: 140px !important;
  }
}

.rounded-md {
  border-radius: 10px !important;
}
.rounded-lg {
  border-radius: 30px !important;
}

//Border
.border {
  border: 1px solid $gray-200 !important;
}
.border-top {
  border-top: 1px solid $gray-200 !important;
}
.border-bottom {
  border-bottom: 1px solid $gray-200 !important;
}
.border-left {
  border-left: 1px solid $gray-200 !important;
}
.border-right {
  border-right: 1px solid $gray-200 !important;
}

//Small
.small,
small {
  font-size: 90%;
}

//card
.card {
  .card-body {
    padding: 1.5rem;
  }
}

@-webkit-keyframes movinglines {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 600%, 0);
    transform: translate3d(0, 600%, 0);
  }
}

@keyframes movinglines {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 600%, 0);
    transform: translate3d(0, 600%, 0);
  }
}

section [data-aos="slide-open"],
section.even [data-aos="slide-open"] {
  width: 100% !important;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
section.even [data-aos="slide-open"].aos-animate {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

section.even svg,
section.odd svg {
  margin: 0 auto;
  display: block;
}

section.overlay {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#00112c),
    to(#051227)
  );
  background: linear-gradient(180deg, #00112c 0%, #051227 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00112c",endColorstr="#051227",GradientType=1);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

section.overlay {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#00d54b),
    to(#02f858)
  );
  background: linear-gradient(180deg, #00d54b 0%, #02f858 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00d54b",endColorstr="#02f858",GradientType=1);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

section.even .overlay {
  width: 50%;
  height: 100%;
  background-color: #00112c;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
}

section.even .overlay-green {
  width: 50%;
  height: 100%;
  background-color: #00d54b;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
}

section.even .wave {
  margin-bottom: 20px;
  width: 60px;
}

.text-display {
  z-index: 10;
}

.dots-display {
  z-index: 10;
}

section.dots-display {
  top: auto;
  bottom: -40px;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.image-display-mobile {
  display: none;

}

@media (max-width: 767px) {
  section.even .image-display,
  section.even .image-display-mobile,
  section.odd .image-display,
  section.odd .image-display-mobile {
    -webkit-box-ordinal-group: 2;
    order: 1;
    margin-top: 72px;
    margin-bottom: 64px;
  }

  section.even .text-display,
  section.odd .text-display {
    -webkit-box-ordinal-group: 3;
    order: 2;
    padding-top: 0;
    padding-bottom: 50px;
  }

  section.even .dots-display,
  section.odd .dots-display {
    -webkit-box-ordinal-group: 4;
    order: 3;
    margin-bottom: 34px;
  }

  section.even .dots-display img,
  section.even .image img,
  section.odd .dots-display img,
  section.odd .image img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .overlay {
    display: none;
  }
  section.overlay {
    display: block;
  }

  .overlay_green {
    display: none;
  }
  section.overlay_green {
    display: block;
  }
}

@media (max-width: 767px) {
  section.dots-display {
    position: absolute;
    height: 180px;
    top: auto;
    overflow: hidden;
    bottom: -24%;
    left: auto;
    width: auto;
    right: -100px;
  }
}
@media (max-width: 767px) {
  section.even .dots-display .rotation-wrapper-outer,
  section.odd .dots-display .rotation-wrapper-outer {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-150px, -40px);
    transform: translate(-150px, -40px);
  }
}

@media (max-width: 480px) {
  section.even .image-display img,
  section.even .image-display-mobile img,
  section.odd .image-display img,
  section.odd .image-display-mobile img {
    padding: 0 15%;
  }
}

@media (max-width: 767px) and (max-width: 992px) {
  section.even .dots-display img,
  section.odd .dots-display img {
    width: 42px;
    min-height: 292px;
  }
}

@media (max-width: 992) {
  .image-display img,
  .image-display-mobile img,
  section.even .dots-display img,
  section.odd .dots-display img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .rotation-wrapper-outer {
    display: table;
  }
  .rotation-wrapper-inner {
    padding: 50% 0;
    height: 0;
  }

  .element-to-rotate {
    display: block;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: rotate(-90deg) translate(-100%);
    transform: rotate(-90deg) translate(-100%);
    margin-top: -50%;
    white-space: nowrap;
  }
}

.element-to-rotate {
  width: 100%;
  height: 100%;
}

@media all and (min-width: 992px) and (-ms-high-contrast: none),
  (min-width: 992px) and (-ms-high-contrast: active) {
  .element-to-rotate {
    min-height: 292px;
  }
}
section.element-to-rotate {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lines-background {
  position: absolute;
  left: 90%;
  top: 0;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.line {
  width: 2px;
  height: 150px;
  display: inline-block;
  background-color: #fff;
  position: relative;
  left: 10%;
  top: -150px;
  -webkit-animation: movinglines 1.5s infinite ease-in;
  animation: movinglines 1.5s infinite ease-in;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.line:nth-child(2) {
  left: 20%;
  width: 1px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.line:nth-child(3) {
  left: 40%;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.line:nth-child(4) {
  left: 60%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.line:nth-child(5) {
  width: 1px;
  left: 80%;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

@media (max-width: 480px) {
  .lines-background {
    display: none;
  }
  .line {
    display: none;
  }
  .image-display {
    display: none;
  }
  .image-display-mobile {
    display: block;
  }
}

@media (max-width: 767px) {
  .lines-background {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .lines-background {
    left: 65%;
    width: 35%;
  }
}

